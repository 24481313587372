<template>
  <div class="container">
    <OnboardingTopBar homepageUrl="/" />
    <LoadingScreen v-if="loading" />
    <div v-else>
      <img src="~@/assets/illustrations/pair/pair-figma.svg" class="figma-logo" />
      <div class="title">Integrate with Figma</div>
      <div class="subtitle">
        Connect your Figma to Anima for seamless code generation from your designs,<br />
        guaranteed secure with read-only access
      </div>
      <div class="actions">
        <an-button @click="grantFigmaAccess">Integrate Anima with Figma</an-button>
        <an-link class="link" variant="primary" @click="navigateToPairing">Skip</an-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { useStore } from '@/store';

export default Vue.extend({
  data() {
    return {
      email: '',
      loading: false
    };
  },
  components: {
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.onMounted();
  },
  methods: {
    async onMounted() {},
    async grantFigmaAccess() {
      const { dispatch } = useStore();
      this.loading = true;
      const callbackUrl = this.$route.query.callbackUrl;
      const figmaOAuthUrl = await dispatch('figmaOAuth/generateFigmaOAuthUrl', { callbackUrl });
      this.$trackEvent('figma.integration.click');
      window.open(figmaOAuthUrl, '_self');
    },
    navigateToPairing() {
      this.$trackEvent('figma.integration.skip');
      const callbackUrl = this.$route.query.callbackUrl;
      const fullUrl = new URL(callbackUrl, window.location.origin);
      fullUrl.searchParams.delete('request_oauth');
      this.$router.replace(`${fullUrl.pathname}${fullUrl.search}`);
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';

.container {
  max-width: 100%;
}

.figma-logo {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  margin-top: 100px;
}
</style>
