<template>
  <div>
    <template v-if="!hideInviteInput">
      <div class="label">Invite team members</div>
      <div class="invite-form" :class="{ focus: focusForm }">
        <div class="invite-form-input" :class="{ focus: focusForm, [$theme]: true }">
          <an-input
            v-model="email"
            placeholder="Email address"
            size="sm"
            noBorder
            :style="{ width: '100%' }"
            :theme="$theme"
            focus
            @keyup.enter="add"
            @focus="focusForm = true"
            @blur="focusForm = false"
            data-cy="email-input"
          />
          <RoleSelect
            :role="newMemberRole.role"
            :accessLevel="newMemberRole.access_level"
            :isDeleted="!focusForm"
            :withDelete="false"
            :theme="$theme"
            allowAll
            @change-role="onSelectRole"
            @focus="focusForm = true"
            @blur="focusForm = false"
          />
        </div>
        <div :class="['add-button', { 'new-layout': newInviteLayout }]">
          <an-button @click="add" v-if="newInviteLayout" variant="secondary">Invite</an-button>
          <AddButton @click="add" :submitted="submittedEmail" :focus="focusForm" v-else />
        </div>
      </div>

      <div class="message">
        <div
          class="disclaimer"
          v-if="
            (isPayingTeam && contributorSelected && !screenOptions) ||
            (screenOptions === 'limited' && countContributors < 1)
          "
        >
          <span v-if="!screenOptions">
            Admin and Contributor roles require a paid seat and will incur an additional charge per seat.
          </span>
        </div>
      </div>
    </template>

    <DefaultLoader v-if="isLoading" />
    <div v-else>
      <div class="invite-link" v-if="owners.length && showInviteWithLink">
        <div class="flex items-center space-between">
          <div class="label">Invite with link</div>
          <an-link
            v-if="newInviteLayout"
            @click="
              enableInviteWithLink = !enableInviteWithLink;
              toggleInviteLink();
            "
          >
            {{ enableInviteWithLink ? 'Disable' : 'Enable' }}
          </an-link>
          <an-toggle v-else class="toggle-wrapper" v-model="enableInviteWithLink" @change="toggleInviteLink" />
        </div>
        <div class="flex items-center">
          <div class="disclaimer flex items-center space-between" v-if="enableInviteWithLink">
            <div class="flex items-center">
              <PopoverMenu
                :items="teamInviteLinkItems"
                @click="updateInviteLink"
                position="right"
                :popoverStyle="{ width: '124px' }"
              >
                <div slot="reference" class="invite-link-select">
                  <span>Anyone with the link will join as a {{ selectedTeamInviteLinkItem.label.toLowerCase() }}</span>
                  <svg-icon name="select-arrow-down" :size="24" />
                </div>
              </PopoverMenu>
            </div>
            <an-link variant="primary" @click="copyInviteLink">Copy link</an-link>
          </div>
        </div>
      </div>
      <div :class="['owner', $theme]" v-if="owners.length">
        <div class="label">{{ ownersLabel }}</div>
        <MemberRow
          v-for="(owner, index) in owners"
          :key="`owner${index}`"
          :member="owner"
          :roleOptions="{ withAdmin: !hasSingleOwner, withDelete: !hasSingleOwner }"
          @change-role="onChangeOwnerRole"
          @remove="onRemove"
          @resend-invite="onResendInvite"
        />
      </div>
      <div class="members" v-if="showMembersList" data-cy="members-list">
        <div class="label">Team members</div>
        <div class="rows">
          <MemberRow
            v-for="member in members"
            :key="`member-${member.id || member.email}`"
            :member="member"
            :roleOptions="{ withAdmin: true, withDelete: member.id.length !== tempIdLength }"
            @change-role="onChangeRole"
            @remove="onRemove"
            @resend-invite="onResendInvite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual, isEmpty, pick } from 'lodash-es';
import { mapState, mapActions, mapGetters } from 'vuex';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import MemberRow from '@/components/Team/Settings/MemberRow';
import RoleSelect from '@/components/Team/Settings/RoleSelect';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import AddButton from '@/components/Button/AddButton';
import DefaultLoader from '@/components/Loading/DefaultLoader';
import { EventBus, toastError, toastSuccess } from '@/services/bus';
import { getScreenOptionFromPlanId, getPrice } from '@/services/subscriptions';
import { uuid } from '@/utils/uuid';
import { deleteArrayItemById, updateArrayItemById } from '@/utils/javascript';
import copy from '@/utils/copyToClp';
import errorHandler from '@/services/errorHandler';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import { TeamMembershipMixin } from '@/mixins';

const contributorMemberRole = { role: 'member', access_level: 'contributor' };
const teamInviteLinkItems = [
  { label: 'Contributor', value: 'contributor' },
  { label: 'Viewer', value: 'viewer' }
];

export default {
  data() {
    return {
      email: '',
      focusForm: false,
      plusIconHover: false,
      newMemberRole: contributorMemberRole,
      owners: [],
      members: [],
      tempIdLength: 9,
      submittedEmail: false,
      submittedInterval: null,
      isWorking: false,
      isLoading: false,
      isInviteInProgress: false,
      enableInviteWithLink: false,
      teamInviteLinkItems,
      selectedTeamInviteLinkItem: teamInviteLinkItems[0]
    };
  },
  props: {
    showInviteWithLink: {
      type: Boolean,
      default: false
    },
    hideInviteInput: {
      type: Boolean,
      default: false
    },
    eventSource: {
      type: String
    },
    onboarding: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddButton,
    MemberRow,
    RoleSelect,
    PopoverMenu,
    SvgIcon,
    DefaultLoader
  },
  mounted() {
    this.fetchData({ sync: true });
    // relevant for first team flow.
    EventBus.$on('add-team-member', this.add);
  },
  destroyed() {
    EventBus.$off('add-team-member', this.add);
  },
  mixins: [TeamMembershipMixin],
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapState('teamInvitations', { teamInvitations: 'items' }),
    ...mapState('teamMemberships', { myTeamMembership: 'currentItem' }),
    ...mapGetters({
      activeSubscription: 'stripeSubscriptions/activeSubscription'
    }),
    teamInvitation() {
      return this.teamInvitations[0];
    },
    screenOptions() {
      const { plan_id: planId } = this.activeSubscription;
      return getScreenOptionFromPlanId(planId);
    },
    iconName() {
      const suffix = this.$theme === 'light' || this.plusIconHover ? '' : '-dark';
      return `circle-plus${suffix}`;
    },
    showMembersList() {
      return !isEmpty(this.members);
    },
    hasSingleOwner() {
      return this.owners.length === 1;
    },
    contributorSelected() {
      return this.newMemberRole.access_level === 'contributor';
    },
    isPayingTeam() {
      if (this.team.uses_stigg_integration) {
        return this.myTeamMembership.team_plan !== 'Free';
      }
      const { product_name = 'Free' } = this.activeSubscription ?? {};
      return product_name !== 'Free';
    },
    eventProps() {
      return { eventSource: this.eventSource || this.$route.params.eventSource };
    },
    newInviteLayout() {
      return !this.onboarding;
    },
    ownersLabel() {
      return this.owners.length === 1 ? 'Owner' : 'Owners';
    },
    countContributors() {
      return this.members.filter((member) => member.access_level === 'contributor').length;
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      createMembership: 'teamMemberships/create',
      updateMembership: 'teamMemberships/update',
      deleteMembership: 'teamMemberships/delete',
      fetchMemberships: 'teamMemberships/fetchAllTeamMemberships',
      resendInvitation: 'teamMemberships/resendInvitation',
      fetchTeamInvitations: 'teamInvitations/fetchAllOfParent',
      createTeamInvitation: 'teamInvitations/create',
      deleteTeamInvitation: 'teamInvitations/delete'
    }),
    async reset() {
      // take email from url query if exists.
      const { query } = this.$route;
      if (query.email && !this.isInviteInProgress) {
        const email = normalizeEmail(query.email);
        this.handleInvite(email);
      }

      // copy memberships
      this.owners = cloneDeep(this.teamMemberships).filter((member) => member.role === 'owner');
      this.members = cloneDeep(this.teamMemberships)
        .filter((m) => m.role !== 'owner')
        .map((m) => {
          const user = isEmpty(m.user?.email) ? { email: m.email } : m.user;
          return { ...m, user };
        })
        .reverse();

      this.fetchData({ skipCache: true });
      this.enableInviteWithLink = !!this.teamInvitation;
    },
    async fetchData({ skipCache = false, sync = false } = {}) {
      if (this.isLoading) {
        return;
      }
      // for this we need: Team, TeamInvitation, TeamMemberships.
      this.isLoading = sync;
      try {
        if (isEmpty(this.team)) {
          const { teamSlug } = this.$route.params;
          await this.fetchTeam({ id: teamSlug, params: { is_slug: true } });
        }
        if (this.showInviteWithLink) {
          this.fetchTeamInvitations({ parent: 'teams', id: this.team.id, skipCache });
        }
        await this.fetchMemberships({ id: this.team.id });
      } finally {
        this.isLoading = false;
      }
    },
    onSelectRole(updatedRole) {
      this.newMemberRole = updatedRole;
    },
    getContributorPrice() {
      const { interval } = this.activeSubscription;
      return getPrice({ plan: 'pro', interval });
    },
    async add() {
      const { team, $route } = this;
      const email = normalizeEmail(this.email);
      const exists =
        this.members.some((m) => normalizeEmail(m.email) === email) ||
        this.owners.some((m) => normalizeEmail(m.email) === email);

      if (isValidEmail(email) && !exists) {
        const id = uuid({ length: this.tempIdLength });
        const newMember = { email, ...this.newMemberRole };
        this.members.unshift({ id, ...newMember });
        this.email = '';

        if (this.submittedInterval) {
          clearTimeout(this.submittedInterval);
          this.submittedInterval = null;
        }

        try {
          this.$trackEvent('team-members.invite-member.click', this.eventProps);

          this.submittedEmail = true;
          this.submittedInterval = setTimeout(() => (this.submittedEmail = false), 3000);

          await this.createMembership({ parent: 'teams', id: team.id, payload: newMember });

          this.$trackEvent('team-members.invite-member.success', this.eventProps);
          this.fetchMemberships({ id: team.id, skipCache: true });

          if ($route.query.email) {
            this.$router.replace({ query: {} });
          }
        } catch (err) {
          this.$trackEvent('team-members.invite-member.failure', { message: err.message, invite_email: email });
          toastError(`We couldn't invite ${email}... :(`);
          this.members = deleteArrayItemById(this.members, id);
        }
      }
    },
    async handleInvite(email) {
      try {
        this.isInviteInProgress = true;
        const { teamSlug } = this.$route.params;
        const member = this.teamMemberships?.find((tm) => normalizeEmail(tm.email) === email);
        if (member && member.access_level !== 'contributor') {
          this.$trackEvent('team-members.invite.update-access-level', this.eventProps);

          this.email = '';
          const updatedRole = { ...contributorMemberRole, role: member.role };

          await this.approveContributorAccess({ email, teamSlug });
          this.onChangeRole({ member, updatedRole }, false);

          toastSuccess(`Success! ${member.email} is now a contributor`);
          this.$router.replace({ query: {} });
        } else {
          this.email = email || '';
          this.newMemberRole = contributorMemberRole;
        }
      } catch (err) {
        errorHandler.captureException(err);
        this.email = email || '';
        this.newMemberRole = contributorMemberRole;
      } finally {
        this.isInviteInProgress = false;
      }
    },
    updateMember(id, { access_level, role }) {
      const payload = { access_level, role };
      return this.updateMembership({ id, payload });
    },
    async onChangeOwnerRole({ updatedRole, member }, sendRequest = true) {
      const { team } = this;
      try {
        this.$trackEvent('team-members.update-member.click', this.eventProps);
        let newMember;

        if (updatedRole.role === 'admin') {
          // move to team members list
          newMember = { ...member, ...updatedRole };
          this.members.unshift(newMember);
          this.owners = deleteArrayItemById(this.owners, member.id);
        } else {
          // change access level
          const { access_level } = updatedRole;
          newMember = { ...member, access_level };
          this.owners = updateArrayItemById(this.owners, member.id, { access_level });
        }

        if (sendRequest) {
          await this.updateMember(member.id, newMember);
          const oldRole = pick(member, ['access_level', 'role']);

          this.$trackEvent('team-members.update-member.success', {
            ...this.eventProps,
            memberId: member.id,
            from: oldRole.access_level,
            to: updatedRole.access_level
          });
          this.fetchMemberships({ id: team.id, skipCache: true });
        }
      } catch (err) {
        this.$trackEvent('team-members.update-member.failure');
        toastError(`We couldn't update the user's role... :(`);

        // undo changes
        if (updatedRole.role === 'admin') {
          this.members = deleteArrayItemById(this.members, member.id);
          this.owners.push(member);
        } else {
          this.owners = updateArrayItemById(this.owners, member.id, { access_level: member.access_level });
        }
      }
    },
    async onChangeRole({ updatedRole, member }) {
      const { team } = this;
      const oldRole = pick(member, ['access_level', 'role']);
      if (isEqual(updatedRole, oldRole)) return;
      try {
        this.$trackEvent('team-members.update-member.click', this.eventProps);

        this.members = updateArrayItemById(this.members, member.id, updatedRole);

        await this.updateMember(member.id, updatedRole);

        this.$trackEvent('team-members.update-member.success', {
          ...this.eventProps,
          memberId: member.id,
          from: oldRole.access_level,
          to: updatedRole.access_level
        });
        if (this.eventSource === 'payment')
          this.$trackEvent('payment.min-contributors-prompt.update-member', {
            from: oldRole.access_level,
            to: updatedRole.access_level
          });
        this.fetchMemberships({ id: team.id, skipCache: true });
      } catch (err) {
        this.$trackEvent('team-members.update-member.failure');
        toastError(`We couldn't update the user's role... :(`);

        // undo changes
        this.members = updateArrayItemById(this.members, member.id, member);
      }
    },
    async onRemove(member) {
      const { team } = this;

      try {
        this.$trackEvent('team-members.remove-member.click', this.eventProps);

        this.members = deleteArrayItemById(this.members, member.id);

        await this.deleteMembership(member);
        const oldRole = pick(member, ['access_level', 'role']);

        this.$trackEvent('team-members.remove-member.success', {
          ...this.eventProps,
          memberId: member.id,
          from: oldRole.access_level
        });
        this.fetchMemberships({ id: team.id, skipCache: true });
      } catch (err) {
        this.$trackEvent('team-members.remove-member.failure');
        toastError(`We couldn't remove the user's role... :(`);

        // undo changes
        this.members.unshift(member);
      }
    },
    async onResendInvite(member) {
      try {
        this.$trackEvent('team-members.resend-invite.click', this.eventProps);

        await this.resendInvitation(member);

        this.$trackEvent('team-members.resend-invite.success', this.eventProps);
        toastSuccess(`Successfully resent invite to ${member.email}.`);
      } catch (err) {
        errorHandler.captureExceptionAndTrack(err, { name: 'team-members.resend-invite.failure' });
      }
    },
    cancel() {
      this.$trackEvent('team-members.cancel-button.click', this.eventProps);
      if (this.isOnboardingFlow) {
        if (this.isDesktop) this.$router.replace({ name: 'root' });
        else this.$router.replace({ name: 'switch-to-desktop' });
      } else {
        this.$emit('close');
      }
    },
    async onCtaClick() {
      if (this.isMobile) {
        this.$router.push({ name: 'switch-to-desktop' });
      } else {
        this.$router.push({ name: 'root' });
      }
    },
    updateInviteLink(teamInviteLinkItem) {
      this.selectedTeamInviteLinkItem = teamInviteLinkItem;
    },
    async toggleInviteLink() {
      if (this.enableInviteWithLink) {
        await this.createTeamInvitation({ parent: 'teams', id: this.team.id });
      } else {
        await this.deleteTeamInvitation(this.teamInvitation);
      }
      this.fetchTeamInvitations({ parent: 'teams', id: this.team.id, skipCache: true });
    },
    getInviteLink() {
      if (!this.teamInvitation) {
        return '';
      }
      const { value: role } = this.selectedTeamInviteLinkItem;
      const propName = `${role}_invite_code`;
      const inviteCode = this.teamInvitation[propName];
      const teamSlug = this.teamInvitation.team_slug;
      return `https://projects.animaapp.com/join/${teamSlug}/${inviteCode}`;
    },
    copyInviteLink() {
      copy(this.getInviteLink());
      toastSuccess('Link copied to clipboard.');
    }
  },
  watch: {
    team: {
      handler: 'reset',
      immediate: true
    },
    teamMemberships: 'reset'
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_utils.scss';
.invite-link-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.invite-form {
  display: flex;
  align-items: center;
  .invite-form-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: var(--border);
    border-radius: 10px;
    padding: 12px 22px;
    transition: border-color 1s ease;
    &.dark {
      border-color: var(--dark-border-color);
      &.focus {
        border-color: #ffffff;
        outline: 0;
      }
    }
    &.focus {
      border-color: var(--secondary);
    }
    .input {
      border: none;
    }
  }
  .add-button {
    height: 44px;
    width: 44px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 1s ease;
    &.dark:hover {
      opacity: 1;
      background-color: #ffffff;
    }
    &.new-layout {
      width: auto;
      height: auto;
    }
  }
}
.owner {
  border-top: var(--border);
  padding-top: 30px;
  margin-top: 30px;
  .label {
    margin-bottom: 0;
  }
  &.dark {
    border-top: var(--dark-border);
  }
}
.invite-link {
  border-top: var(--border);
  padding-top: 30px;
  margin-top: 30px;
  .label {
    margin-bottom: 0;
  }
}
.members {
  margin-top: 30px;
}
.select {
  display: flex;
  cursor: pointer;
}
.warning {
  color: var(--primary);
}
.team-name {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: var(--border);
}

.alert {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  padding: 14px;
  height: 50px;
  margin-bottom: 1rem;

  &.alert--warning {
    background-color: hsla(0, 79%, 94%, 0.53);
  }

  span {
    margin-left: 0.5rem;
  }

  @include mobile {
    width: 100%;
    font-size: 13px;
    line-height: 18px;
  }
}
</style>
