<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <LoadingScreen v-if="loading" />
    <div class="container dark" v-else>
      <div class="title">Log in with SSO</div>
      <div class="subtitle">Log in with your SSO provider</div>
      <div class="body">
        <div class="label">Please enter your work email address</div>
        <div class="input">
          <an-input
            id="email"
            type="email"
            v-model="email"
            :theme="theme"
            :invalid="!emailValid"
            placeholder="Email address"
            focus
            @input="showButton = true"
            @keyup.enter="submit"
          />
        </div>
        <transition name="fadeIn">
          <div class="actions" v-if="showButton">
            <an-button @click="submit" :isWorking="isWorking">Continue</an-button>
          </div>
        </transition>
        <div class="link">
          <an-link @click="navigateToLogin" :theme="theme">Return to log in page</an-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import api from '@/api';
import { toastError } from '@/services/bus';
import { SignupMixin } from '@/mixins';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      emailValid: true,
      showButton: false,
      isWorking: false,
      theme: 'dark',
      loading: false
    };
  },
  mixins: [SignupMixin],
  components: {
    OnboardingTopBar,
    LoadingScreen
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.showButton = true;
    }

    if (this.$route.query.state) {
      this.loading = true;
      this.completeLogin();
    }

    this.applyTheme(this.theme, 'onboarding');
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne'
    }),
    async completeLogin() {
      try {
        const { data } = await api.post('/sso/auth', { state: this.$route.query.state });
        const { is_login, access_token } = data;
        localStorage.setItem('token', access_token);

        const user = await this.getUser({ id: 'me', skipCache: true });

        this.$tracking.setUserEmail(user.email);
        this.$tracking.setUserId(user.id);
        this.$tracking.alias(user.id);

        if (!is_login) {
          this.$trackEvent('signup.signup.success', {
            cta: 'sso',
            device_id: this.currentDevice.device_id,
            email: user.email
          });
          this.signupExperiments();
        } else {
          this.$trackEvent('login.login.success', {
            cta: 'sso',
            device_id: this.currentDevice.device_id,
            email: user.email
          });
        }

        this.redirectAfterAuth(is_login);
      } catch (err) {
        toastError('Something went wrong! Please try again');
        this.$trackEvent('login-sso.complete-login.failure');
        this.$router.replace({ name: 'login' });
      }
    },
    async submit() {
      try {
        this.isWorking = true;
        const email = normalizeEmail(this.email);
        this.emailValid = isValidEmail(email);

        this.$trackEvent('login-sso.submit.click', { email });

        if (this.emailValid) {
          const queryParams = new URLSearchParams(this.$route.query);
          const { data } = await api.post('/sso/login', { email, query: queryParams.toString() });
          this.$trackEvent('login-sso.idp-login-url.success', { email });
          window.location.href = data.url;
        } else {
          if (this.isDesktop) toastError('Oops, did you provide an email?');
        }
      } catch (err) {
        const { response } = err;
        const message = response?.data?.message || 'Something went wrong! Is that really your email';
        this.$trackEvent('login-sso.idp-login-url.failure', { email: this.email });
        if (this.isDesktop) toastError(message);
      } finally {
        this.isWorking = false;
      }
    },
    navigateToLogin() {
      this.$trackEvent('login-sso.return-to-login.click');
      this.$router.replace({ name: 'login', query: this.$route.query });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  margin-top: 160px;
  max-width: 100%;
  @include mobile {
    margin-top: 110px;
  }
  .subtitle {
    @include desktop {
      margin: 20px 0 0;
      width: 100%;
    }
  }
  .body {
    margin: 40px auto;
    max-width: 444px;
  }
}
.actions {
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    &.an-button {
      @include mobile {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}
.link {
  text-align: center;
  margin: 40px 0 0;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
</style>
